





































import Vue, { PropType } from 'vue';
import { Batch, Asset } from '@/types';
import { ExtendedAssetMedia, AssetAttribute } from './types';
import AssetMediaModal from './AssetMediaModal.vue';
import AssetAttributeModal from './AssetAttributeModal.vue';

export default Vue.extend({
  name: 'BatchAssetsTable',
  components: {
    AssetMediaModal,
    AssetAttributeModal
  },
  props: {
    assets: {
      required: true,
      type: Array as PropType<Asset[]>
    },
    batch: {
      required: true,
      type: Object as PropType<Batch[]>
    }
  },
  data() {
    return {
      openedMedia: null as ExtendedAssetMedia | null,
      openedAttribute: null as AssetAttribute | null,
      headers: [
        {
          text: 'External ID',
          align: 'start',
          sortable: true,
          value: 'external_id'
        },
        {
          text: 'Attributes',
          align: 'center',
          sortable: false,
          value: 'attributes'
        },
        {
          text: 'Media',
          align: 'center',
          sortable: false,
          value: 'media'
        }
      ]
    };
  },
  computed: {
    items(): Asset[] {
      return this.assets;
    }
  },
  methods: {
    setOpenedMedia(asset: Asset, mediaKey: string) {
      this.openedMedia = {
        ...asset.media[mediaKey],
        asset,
        slug: mediaKey
      };
    },
    setOpenedAttribute(asset: Asset, attributeKey: string) {
      this.openedAttribute = {
        asset,
        key: attributeKey,
        value: asset.attributes[attributeKey]
      };
    }
  }
});
