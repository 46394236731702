






import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRefs
} from '@vue/composition-api';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default defineComponent({
  props: {
    mediaType: {
      required: true,
      type: String
    },
    media: {
      required: true,
      type: ArrayBuffer
    }
  },
  setup(props) {
    const { mediaType, media } = toRefs(props);

    const blob = computed(() => new Blob([media.value]));

    const url = computed(() => URL.createObjectURL(blob.value));

    const byteLength = media.value.byteLength;

    const player = ref(null);

    const videoPlayerRef = ref(null);
    const videoCanvasRef = ref(null);

    const options = computed(() => ({
      autoplay: true,
      controls: true,
      sources: {
        src: url.value,
        type: mediaType.value
      }
    }));

    const videoElement = ref(null as HTMLVideoElement);

    function drawImage() {
      /** Example of drawing canvas from the contents of a video element */
      var ctx = videoCanvasRef.value.getContext('2d');

      ctx.drawImage(
        videoElement.value,
        0,
        0,
        videoCanvasRef.value.width,
        videoCanvasRef.value.height
      );

      console.log(
        'video width',
        player.value.videoWidth(),
        'video height',
        player.value.videoHeight(),
        'current width',
        player.value.currentWidth(),
        'current height',
        player.value.currentHeight()
      );

      player.value.requestAnimationFrame(drawImage);
    }

    onMounted(() => {
      player.value = videojs(
        videoPlayerRef.value,
        options.value,
        function onPlayerReady() {
          console.log('Player ready');
        }
      );

      videoElement.value = player.value.children_[0];
      // drawImage();
    });

    onUnmounted(() => {
      if (player.value) {
        console.log(`Disposing video player`);
        player.value.dispose();
      }
    });

    return {
      byteLength,
      player,
      videoPlayerRef,
      url,
      videoElement,
      videoCanvasRef
    };
  }
});
