




















































import Vue, { PropType } from "vue";
import { ExtendedAssetMedia } from "./types";
import { arrayBufferToBase64 } from "@/api/utils";
import VideoPlayer from "./VideoPlayer.vue";

export default Vue.extend({
  props: {
    value: {
      required: false,
      type: Object as PropType<ExtendedAssetMedia>,
    },
  },
  components: {
    VideoPlayer,
  },
  data: () => ({
    showDialog: false,
    loading: false,
    errorLoading: null as string | null,
    media: null as ArrayBuffer | null,
  }),
  computed: {
    mediaType(): string {
      return this.value.media_type;
    },
    isImage(): boolean {
      return this.mediaType === "image/jpeg" || this.mediaType === "image/png";
    },
    imageAsBase64(): string | undefined {
      return this.isImage && this.media
        ? arrayBufferToBase64(this.media)
        : undefined;
    },
    isText(): boolean {
      return this.mediaType.startsWith("text/");
    },
    isCsv(): boolean {
      return this.mediaType === "text/csv";
    },
    isJson(): boolean {
      return this.mediaType === "application/json";
    },
    isVideo(): boolean {
      return this.mediaType.startsWith("video/");
    },
    asCsv(): string | undefined {
      return this.isCsv && this.media
        ? this.decodeToText(this.media)
        : undefined;
    },
    asJson(): string | undefined {
      if (this.isJson && this.media) {
        const decodedText = this.decodeToText(this.media);
        const prettifiedJson = JSON.stringify(JSON.parse(decodedText), null, 2).replace(/\\n/g, '\n');
        return prettifiedJson;
      } else {
        return undefined;
      }
    },
    asText(): string | undefined {
      return this.isText && this.media
        ? this.decodeToText(this.media)
        : undefined;
    },
  },
  methods: {
    close() {
      this.media = null;
      this.$emit("input", null);
    },
    decodeToText(media: ArrayBuffer) {
      return new TextDecoder().decode(media);
    },
    async fetchData() {
      console.log(`Fetching data for ${this.value.slug}`);
      this.loading = true;
      this.errorLoading = null;
      try {
        const assetId = this.value.asset.id;
        const mediaSlug = this.value.slug;
        const mediaType = this.value.media_type;
        this.media = await this.$api.assets.getMedia({
          assetId,
          mediaSlug,
          mediaType,
        });
      } catch (err) {
        this.errorLoading = err;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    value: function (newValue) {
      if (newValue) {
        this.fetchData();
      }
      this.showDialog = !!newValue;
    },
  },
});
