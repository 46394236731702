
































































































































































import Vue from 'vue';

import { Batch, Dataset } from '@/types';
import { ApiException } from '@/api/client';
import LoadingModal from '@/components/LoadingModal.vue';
import DatasetBatchView from './DatasetBatchView.vue';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

export interface BatchItem {
  batchId: string;
}

export default Vue.extend({
  name: 'DatasetBrowseView',
  components: {
    LoadingModal,
    DatasetBatchView
  },
  props: {
    orgId: {
      required: true,
      type: String
    },
    datasetId: {
      required: true,
      type: String
    },
    batchId: {
      required: false,
      type: String
    }
  },
  data: () => ({
    dataset: null as Dataset | null,
    batches: null as Batch[] | null,
    loading: false,
    error: null,
    selectedBatch: null as Batch | null,
    items: null as null | BatchItem[]
  }),
  created() {
    this.initialize();
  },
  computed: {
    datasetCreatedAt(): string | undefined {
      return this.dataset
        ? new Date(Date.parse(this.dataset.created_at)).toDateString()
        : null;
    },
    noBatches(): boolean {
      return !this.batches || this.batches.length === 0;
    },
    hasBatches(): boolean {
      return this.batches && this.batches.length > 0;
    },
    toDatasets(): Record<string, any> {
      return {
        name: 'Datasets',
        params: {
          orgId: this.orgId
        }
      };
    },
    hasBatchId(): boolean {
      return this.batchId && this.batchId !== 'null';
    }
  },
  methods: {
    setChosenBatch() {
      if (!this.hasBatchId && this.hasBatches) {
        const firstBatch = this.batches[0];
        console.log(`Navigating to batch ${firstBatch.id}`);
        this.$router.replace({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            batchId: firstBatch.id
          }
        });
      } else if (this.hasBatchId && this.hasBatches) {
        console.log(`Setting selected batch ${this.batchId}`);
        this.selectedBatch = _find(
          this.batches,
          batch => batch.id === this.batchId
        );
      } else {
        this.selectedBatch = null;
      }
    },
    iconForMediaType(mediaType) {
      switch (mediaType) {
        case 'text/csv':
          return 'table';
        default:
          return 'image-outline';
      }
    },
    async initialize() {
      await this.fetchData();
      // await this.setActiveBatchData();
    },
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        this.dataset = await this.$api.datasets.getDataset({
          datasetId: this.datasetId
        });
        const batchIds = this.dataset.batches.map(batch => batch.id); // TODO This list should come from separate endpoint
        const batches = await Promise.all(
          batchIds.map(async batchId => {
            const extendedBatch = await this.$api.batches.getBatch({
              batchId
            });
            return extendedBatch;
          })
        );
        this.batches = _sortBy(batches, batch => batch.created_at);
        this.setChosenBatch();
      } catch (err) {
        if (err instanceof ApiException) {
          this.error = `Failed fetching dataset with code: ${err.code}`;
        } else {
          this.error = `Unknown exception fetching data: ${err}`;
        }
      } finally {
        this.loading = false;
      }
    }
  },
  filters: {
    toDate: function(created_at: string) {
      return new Date(Date.parse(created_at)).toLocaleDateString();
    }
  },
  watch: {
    datasetId: function() {
      this.fetchData();
    },
    batchId: function() {
      this.fetchData();
    }
  }
});
