

















import Vue, { PropType } from 'vue';
import { AssetAttribute } from './types';

export default Vue.extend({
  props: {
    value: {
      required: false,
      type: Object as PropType<AssetAttribute>
    }
  },
  data: () => ({
    showDialog: false
  }),
  computed: {},
  methods: {
    close() {
      this.$emit('input', null);
    }
  },
  watch: {
    value: function(newValue) {
      this.showDialog = !!newValue;
    }
  }
});
