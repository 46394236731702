



















import Vue, { PropType } from 'vue';
import { Asset, Batch, Dataset } from '@/types';
import LoadingModal from '../LoadingModal.vue';
import BatchAssetsTable from './BatchAssetsTable.vue';

export default Vue.extend({
  name: 'DatasetBatchView',
  components: {
    LoadingModal,
    BatchAssetsTable
  },
  props: {
    batch: {
      required: true,
      type: Object as PropType<Batch>
    },
    dataset: {
      required: true,
      type: Object as PropType<Dataset>
    }
  },
  data: function() {
    return {
      loading: false,
      error: null as string | null,
      assets: null as Asset[] | null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.error = null;
      this.loading = true;
      try {
        this.assets = await this.$api.batches.getAssets({
          batchId: this.batch.id
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    batch: function() {
      this.fetchData();
    }
  }
});
